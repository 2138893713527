import { policyList } from "@/enums/permissions";
import { PusherNotification } from "@/interfaces/notification";
import {
  TaxBreak,
  TaxCategory,
  TaxCategoryDefault
} from "@/interfaces/taxCategory";
import { EventBus, pusherEvents } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { store } from "@/store";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  PageTitleComponent,
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableSuccessModalResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Validator } from "vee-validate";
import { Component, Inject, Provide, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import BreakdownTypeModalComponent from "./breakdownType/BreakdownType.component";
import Template from "./TaxCategoryForm.template.vue";

/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace = "TaxCategoryModule";
@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    PageTitleComponent,
    TableComponent,
    BreakdownTypeModalComponent
  },
  inject: ["$changes"]
})
export default class TaxCategoryFormComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;
  @Provide()
  public validator = this.$validator;
  public isSaving = false;
  public i = 0;
  public headers: TableHeader[] = [];
  public edit = false;

  public generalActions: TableAction[] = [
    {
      icon: "fal fa-plus",
      id: "btn_add_tax",
      action: (...arg: any[]) => {
        this.modalToggle();
      },
      visibleCondition: () => !this.hasModifyPermission
    }
  ];
  public rowActions: TableAction[] = [];

  @Action("loadTaxCategories", { namespace })
  public loadTaxCategories!: Callback;

  @Action("findTaxCategory", { namespace })
  public findTaxCategory!: Callback;

  @Action("saveTaxCategory", { namespace })
  public saveTaxCategoryAction!: Callback;

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Getter("taxCategory", { namespace })
  public current!: TaxCategory;
  public taxCategoryModel: TaxCategory = cloneDeep(TaxCategoryDefault);
  public itemForEdition!: object;
  public hasModifyPermission: boolean = false;

  public modalToggle(itemForEdition?: TaxBreak) {
    this.itemForEdition = itemForEdition || {};
    this.$modals
      .load<{ edit: boolean; tax: TaxBreak }>(
        BreakdownTypeModalComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          tax: this.itemForEdition
        }
      )
      .then(async data => {
        if (data.edit) {
          this.editTax(data.tax);
        } else {
          this.addNewTax(data.tax);
        }
      })
      .catch(() => {
        // No action on close.
      });
  }
  public async openConfirmationModal(): Promise<boolean> {
    const message = {
      title: "",
      acceptButton: "yes",
      cancelButton: "no",
      text: "",
      html: this.modalMessage
    };
    const confirmation = await this.$modals.loadConfirmation(message, {
      positionX: "center",
      size: "normal"
    });
    return confirmation;
  }

  public get modalMessage(): string {
    const text1 = this.$t(
      this.$store.state.AuthModule.currentRetailSettings.retail_pre_tax_pricing
        ? "post_tax_disabled"
        : "post_tax_enabled"
    ).toString();
    const text2 = this.$t(
      this.$store.state.AuthModule.currentRetailSettings
        .wholesale_pre_tax_pricing
        ? "post_tax_disabled"
        : "post_tax_enabled"
    ).toString();
    return `
    <strong>${this.$t("inventory.retail_pricing").toString()}</strong><br>
    <p>${text1}</p>
    <strong>${this.$t("inventory.wholesale_pricing").toString()}</strong><br>
    <p>${text2}</p>
    <center>${this.$t("under_age_customer_question").toString()}</center>
    <br>
   `;
  }

  public setRowActions() {
    this.rowActions = [
      {
        icon: "fal fa-pen",
        id: "btn_edit_tax",
        action: (arg: TaxBreak) => {
          this.modalToggle(arg);
        },
        visibleCondition: () => !this.hasModifyPermission
      },
      {
        icon: "fal fa-trash-alt",
        id: "btn_delete_tax",
        modalActions: {
          modalNumber: 1,
          modalQuestion: this.$t("tax_category_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            this.deleteTax(arg.item);
            arg.unselectModal();
          },
          modalCancelText: "No",
          modalIdCancel: "btn_delete_no"
        },
        visibleCondition: () => !this.hasModifyPermission
      }
    ];
  }

  public deleteTax(taxToDelete: TaxBreak) {
    const position = this.taxCategoryModel.taxes!.indexOf(taxToDelete);
    const taxes = cloneDeep(this.taxCategoryModel.taxes!);
    if (!taxes[position].id) {
      taxes.splice(position, 1);
    } else {
      // @ts-ignore
      taxes[position]._destroy = true;
    }
    this.taxCategoryModel.taxes = taxes;
  }

  public editTax(newValue: TaxBreak) {
    // @ts-ignore
    const copy = this.taxCategoryModel.taxes.map((element: TaxBreak) => {
      if (element.id && newValue.id && isEqual(element.id, newValue.id)) {
        return { ...newValue };
      } else if (
        element.temporalId &&
        newValue.temporalId &&
        isEqual(element.temporalId, newValue.temporalId)
      ) {
        return { ...newValue };
      } else {
        return { ...element };
      }
    });
    this.taxCategoryModel.taxes = copy;
    this.parseTable();
    this.edit = true;
  }

  public addNewTax(newValue: TaxBreak) {
    this.i++;
    this.taxCategoryModel.taxes!.push({ ...newValue, temporalId: this.i });
    this.parseTable();
    this.edit = true;
  }
  public async saveTaxCategory() {
    let confirmation = false;
    if (this.edit) {
      confirmation = await this.openConfirmationModal();
    }
    const validator = await this.$validator.validateAll();
    if (validator && (confirmation || !this.edit)) {
      this.isSaving = true;
      // this return al taxes to number format so backend can process it
      this.taxCategoryModel.taxes =
        this.taxCategoryModel.taxes &&
        this.taxCategoryModel.taxes.map((e: TaxBreak) => {
          e.rate = parseFloat(String(e.rate!).replace(/[$%]/g, ""));
          return e;
        });
      await this.saveTaxCategoryAction(this.taxCategoryModel);
      this.isSaving = false;
      // @ts-ignore
      this.$refs.taxForm.reset();
      this.$router.push({ name: "tax-category-list" });
    }
  }

  public cancel() {
    this.$router.push({ name: "tax-category-list" });
  }

  public parseTable() {
    this.taxCategoryModel.taxes =
      this.taxCategoryModel.taxes &&
      this.taxCategoryModel.taxes.map((e: TaxBreak) => {
        e.rate =
          e.type !== "PER_TRANSACTION"
            ? String(e.rate!).indexOf("%") < 0
              ? `${e.rate} %`
              : e.rate
            : String(e.rate!).indexOf("$") < 0
            ? `$ ${(+e.rate!).toFixed(2)}`
            : e.rate;
        // e.type = String(this.$t(String(e.type)));
        return e;
      });
  }

  public notImplementedYet() {
    EventBus.$emit("notify", { text: "not_implemented_yet", color: "warning" });
  }

  public setHeaders() {
    this.headers = [
      {
        value: "name",
        label: i18n.t("tax_name").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: false
      },
      {
        value: "rate",
        label: i18n.t("rate").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: false
      },
      {
        value: "type",
        label: i18n.t("type").toString(),
        fieldComponent: TableFieldType.enumText,
        enum: {
          EXCISE_PRE_TAX: this.$t("EXCISE_PRE_TAX"),
          EXCISE_POST_TAX: this.$t("EXCISE_POST_TAX"),
          NORMAL: this.$t("NORMAL"),
          PER_TRANSACTION: this.$t("PER_TRANSACTION")
        },
        class: "tdt__headers__fieldLong",
        sortable: false
      }
    ];
  }

  protected async mounted() {
    if (this.$route.name === "tax-category-view") {
      this.hasModifyPermission = true;
    }
    this.setPageNav({
      title: "tax_categories",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: this.saveTaxCategory,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.setRowActions();
    this.setHeaders();
    this.$changes.watch(
      pusherEvents.taxCategoryTouched,
      () => {
        this.taxCategoryModel.id = null;
        this.loadTable();
      },
      (data: PusherNotification) => {
        return (
          !!this.$route.params.id &&
          !!data.message.find(n => n.item_id === +this.$route.params.id)
        );
      }
    );
    this.loadTable();
  }

  protected async loadTable() {
    if (this.$route.params.id && this.taxCategoryModel.id === null) {
      await this.findTaxCategory({ id: this.$route.params.id });
      this.taxCategoryModel = { ...this.current };
      this.taxCategoryModel.taxes = this.taxCategoryModel.taxes
        ? this.taxCategoryModel.taxes
        : [];
    }

    this.parseTable();
  }
}
