import { TaxBreak } from "@/interfaces/taxCategory";
import { EventBus } from "@/internal";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Provide, Vue } from "vue-property-decorator";
import Template from "./BreakdownType.template.vue";

@Component({
  mixins: [Template]
})
export default class BreakdownTypeModalComponent extends Vue {
  @Prop({})
  public tax!: TaxBreak;
  public taxEdition: TaxBreak = { name: "", type: "", rate: "" };
  public alreadyOnTable: boolean = false;

  @Inject("$validator") public $validator!: Validator;

  @Provide()
  public validator = this.$validator;

  public valid = false;
  public dirty = false;

  public taxBreakdownType: string[] = [
    "NORMAL",
    "PER_TRANSACTION",
    "EXCISE_PRE_TAX",
    "EXCISE_POST_TAX"
  ];

  public getTaxBreakdownTypeTranslation(item: string): string {
    return this.$t(item).toString();
  }

  public cancel() {
    this.$emit("reject");
  }

  public async ok() {
    const validator = await this.$validator.validateAll();
    if (validator) {
      this.$emit("resolve", {
        edit: this.alreadyOnTable,
        tax: this.taxEdition
      });
    }
  }

  public mounted() {
    if (Object.keys(this.tax).length) {
      this.taxEdition = { ...this.tax };
      this.alreadyOnTable = true;
      this.unparseRate();
    }
  }

  public positiveNumber(value: number) {
    return value >= 0 || this.$t("negative_zero_error").toString();
  }

  public unparseRate() {
    // this returns string to number type so it can be modified
    this.taxEdition.rate = parseFloat(
      String(this.taxEdition.rate!).replace(/[$%]/g, "")
    );
  }

  public notImplementedYet() {
    EventBus.$emit("notify", { text: "not_implemented_yet", color: "warning" });
  }

  public onChange() {
    if (!this.dirty) {
      this.dirty = true;
    }
  }
}
